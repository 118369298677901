<template>
   <el-col class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>营销云</el-breadcrumb-item>
            <el-breadcrumb-item>转换</el-breadcrumb-item>
            <el-breadcrumb-item>商城转换</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>

      <el-row class="secondarymarketing">
         <el-row class="funnel">
            <el-row class="title">商城总销量 <i class="el-icon-refresh" @click="salesVolumeStatisticsa()"></i></el-row>
            <el-row class="row-data-box">
               <el-col :span="12">过去7日，按日，所有用户</el-col>
               <el-col :span="12">
                  <date-packer :datePacker="orderDateVal" @setDatePacker="getOrderDatePacker"></date-packer>
               </el-col>
            </el-row>
            <el-table :data="totalSales" style="width: 100%">
               <el-table-column prop="date" label="日期"></el-table-column>
               <el-table-column prop="totalMoney" sortable label="商城支付订单的商城订单金"></el-table-column>
            </el-table>
         </el-row>

         <el-row class="funnel">
            <el-row class="title">商城购买转化率 <i class="el-icon-refresh" @click="getOrderConverts()"></i></el-row>
            <el-row class="row-data-box">
               <el-col :span="12">过去7日，按日，所有用户</el-col>
               <el-col :span="12">
                  <date-packer :datePacker="orderDateVals" @setDatePacker="getOrderDatePackers"></date-packer>
               </el-col>
            </el-row>
            <el-row >
               <el-table :data="percentConversion" style="width: 100%">
                  <el-table-column prop="date" label="日期"></el-table-column>
                  <el-table-column sortable label="商城转化率">
                     <template slot-scope="scope">{{scope.row.conversion}}</template>
                  </el-table-column>
               </el-table>
            </el-row>
         </el-row>
      </el-row>

      <el-row class="secondarymarketing">
         <el-row class="funnel">
            <el-row class="title">商城客户单客销量 <i class="el-icon-refresh" @click="singlepassengerSalesa()"></i></el-row>
            <el-row class="row-data-box">
               <el-col :span="12">过去7日，按日，所有用户</el-col>
               <el-col :span="12">
                  <date-packer :datePacker="orderDateValar" @setDatePacker="getOrderDatePackerarsa"></date-packer>
               </el-col>
            </el-row>
            <el-table :data="tableData" style="width: 100%">
               <el-table-column prop="date" label="日期"></el-table-column>
               <el-table-column prop="saleCount" sortable label="商城客户单客销量"></el-table-column>
            </el-table>
         </el-row>

         <el-row class="funnel">
            <el-row class="title">商城热销商品 <i class="el-icon-refresh" @click="getOrderConverts()"></i></el-row>
            <el-row class="row-data-box">
               <el-col :span="12">过去7日，按日，所有用户</el-col>
               <el-col :span="12">
                  <date-packer :datePacker="orderDateVall" @setDatePacker="getOrderDatePackerl"></date-packer>
               </el-col>
            </el-row>
            <el-row >
               <el-row id="echa" style="width: 85%; height: 300px; position: relative; top: 2rem;"></el-row>
            </el-row>
         </el-row>
      </el-row>
   </el-col>
</template>

<script>
import { urlObj } from '@/api/interface'
import { mapState } from 'vuex'
import {dateFactory} from "@/common/js/common";
export default {
    watch: {
        hotelInfo: {
            handler(newVal, oldVal) {
                if (newVal.id !== oldVal.id && oldVal) {
                    this.hotelId = this.hotelInfo.id
                    this.timead()
                }
            },
        },
        //观察option的变化
        'option': {
            handler(newVal, oldVal) {
                if (this.myChart) {
                    if (newVal) {
                        this.myChart.setOption(newVal);
                    } else {
                        this.myChart.setOption(oldVal);
                    }
                } else {
                    this.init();
                }
            },
            deep: true //对象内部属性的监听，关键。
        },
    },
    computed: {
        ...mapState(['hotelInfo'])
    },
    data(){
        return {
        orderDateValar:[],
        orderDateVals:[],
        orderDateVal:[],
        orderDateVall:[],
            name:'',
            saleCount:'',
            option : {
                xAxis: {
                    type: 'category',
                    data: []
                },
                yAxis: {
                    type: 'value'
                },
                series: [{
                    data: [],
                    name: '2011年',
                    type: 'bar'
                }]
            },
            percentConversion:[
            {data:'2021-03-20',actualAmount:'1'}
            ],          //商城购买转化率
            totalSales:[
            {data:'2021-03-20',actualAmount:'5%'}
            ],                 //总销售
            tableData: [
            {data:'2021-03-20',actualAmount:'10'}
            ],
            orderBeginTime:'',
            orderBeginTimer:'',
            orderEndTimer:'',
            orderEndTime:'',
            orderBeginTimes:'',
            orderEndTimes:'',
            orderBeginTimel:'',
            orderEndTimel:'',
            myChart:'',
            dataArr:[],
            date2:'',
            hotelId:'',
        }
    },
    mounted() {
        this.hotelId = this.hotelInfo.id
       this.date2 = dateFactory.dateFormat(false)
       let times = dateFactory.getDistanceAppoint(this.date2, '-', 7, false);
       this.orderDateValar = [times,this.date2]
       this.orderDateVal = [times,this.date2]
       this.orderDateVall = [times,this.date2]
       this.orderDateVals = [times,this.date2]
       this.orderBeginTimes =  this.orderBeginTime = this.orderBeginTimel = this.orderBeginTimer = times
       this.orderEndTimel  =  this.orderEndTimes  = this.orderEndTimer = this.orderEndTime = this.date2
       this.getOrderConverts()
       this.salesVolumeStatisticsa()
       this.singlepassengerSalesa()
       this.echar()
    },
    methods:{

        //商城购买转化率
        getOrderConverts(){
            const url = urlObj.getOrderConvert;
            const param = {
                hotelId:this.hotelId,
                beginTime:this.orderBeginTimes,
                endTime:this.orderEndTimes,
            };
            this.$axios.get(url, param).then((res) => {
                this.percentConversion = res.records
                this.percentConversion.map(item=>{
                    let percent = item.paidCount/item.unpaidCount
                    if (percent !== Number) {
                        this.$set(item, 'conversion', 0)
                    }
                })

            });
        },
        //商城总销售
        salesVolumeStatisticsa(){
            const url = urlObj.salesVolumeStatistics;
            const param = {
                hotelId:this.hotelId,
                beginTime:this.orderBeginTimel,
                endTime:this.orderEndTimel,
            };
            this.$axios.get(url, param).then((res) => {
                this.totalSales = res.records
            });
        },
        //单客销售
        singlepassengerSalesa(){
            const url = urlObj.getRegularCustomerOrderStatistics;
            const param = {
                hotelId:this.hotelId,
                beginTime:this.orderBeginTimer,
                endTime:this.orderEndTimer,
            };
            this.$axios.get(url, param).then((res) => {
                this.tableData = res.records
            });
        },

        // 工单统计时间段过滤
        getOrderDatePackerarsa(val) {
            this.orderDateValar = val;
            if (this.orderDateValar) {
                this.orderBeginTimer = this.orderDateValar[0].split(" ")[0];
                this.orderEndTimer = this.orderDateValar[1].split(" ")[0];
            } else {
                this.orderBeginTimer = "";
                this.orderEndTimer = "";
            }
            this.singlepassengerSalesa()
        },

        // 工单统计时间段过滤
        getOrderDatePacker(val) {
            this.orderDateVal = val;
            if (this.orderDateVal) {
                this.orderBeginTimel = this.orderDateVal[0].split(" ")[0];
                this.orderEndTimel = this.orderDateVal[1].split(" ")[0];
            } else {
                this.orderBeginTimel = "";
                this.orderEndTimel = "";
            }
            // this.orderDateType = "";
            this.salesVolumeStatisticsa()
        },
        // 工单统计时间段过滤
        getOrderDatePackers(val) {
            this.orderDateVals = val;
            if (this.orderDateVals) {
                this.orderBeginTimes = this.orderDateVals[0].split(" ")[0];
                this.orderEndTimes = this.orderDateVals[1].split(" ")[0];
            } else {
                this.orderBeginTimes = "";
                this.orderEndTimes = "";
            }
            this.getOrderConverts()
        },

        // 工单统计时间段过滤
        getOrderDatePackerl(val) {
            this.orderDateVall = val;
            if (this.orderDateVall) {
                this.orderBeginTime = this.orderDateVall[0].split(" ")[0];
                this.orderEndTime = this.orderDateVall[1].split(" ")[0];
            } else {
                this.orderBeginTime = "";
                this.orderEndTime = "";
            }
            this.echar()
        },
        echar(){
            const url = urlObj.hotSaleStatistics;
            const param = {
                hotelId:this.hotelId,
                beginTime:this.orderBeginTime,
                endTime:this.orderEndTime,
            };
            this.$axios.get(url, param).then((res) => {
                let arr = []
                arr = res.records
                arr.forEach(item => {
                    this.option.series[0].data.push(item.saleCount)
                    this.option.series[0].name = item.title
                    this.option.xAxis.data.push(item.title)
                })
            });
            // this.$nextTick(() => {
                this.myChart = this.$echarts.init(document.getElementById("echa"));
                this.myChart.setOption(this.option, true);
                this.$forceUpdate()
            // });
        }
    },
}
</script>

<style scoped lang="scss">

.secondarymarketing{
   display: flex;
   height: auto;
   margin: 0px 20px;
   margin-bottom: 20px;
   .funnel{
      width: 49%;
      background-color: white;
      box-shadow: 0px 0px 20px #dce7f9;
      .title{
         height: 50px;
         line-height: 50px;
         background: #f7f7f8;
         padding: 0px 20px;
         i{
            float: right;
            display: block;
            font-size: 20px;
            padding: 1rem;
            cursor: pointer;
         }
      }
      .row-data-box{
         height: 50px;
         line-height: 50px;
         padding:20px 20px 0px ;
      }
      .echa-box{
         padding: 0px 20px;
      }
   }
   .funnel:last-child{
      margin-left: 2%;
   }
}
.grosssales{width: 49%; margin: auto; height: 100%; box-shadow: 0px 0px 20px #dce7f9; float: left; margin-left: 1rem;}
.percent{width: 49%; margin: auto; height: 100%; box-shadow: 0px 0px 20px #dce7f9; float: right;}
.titles{font-size: 14px; float: left;}
.tiem{float: left; margin-left: 2rem;}
 /deep/ .has-gutter tr th{text-align: center; border-bottom: 2px solid #EEEEEE;}
 /deep/ tbody .el-table__row td{text-align: center;}
 .bottom{width: 98%; margin: auto; height: 500px;}
 .singlepassengersales{width: 49%;height: 100%; box-shadow: 0px 0px 20px #dce7f9; margin-top: 2rem; float: left;}
 .hotcommodity{ width: 49%;height: 100%; box-shadow: 0px 0px 20px #dce7f9; margin-top: 2rem; float: right;}
@media screen and (max-width: 1700px) {
  .main {
    width: 1300px;
  }
}
</style>
